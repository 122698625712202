* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100%;
}

#root {
  padding: 0;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.slick-track {
  width: 100%;
}

.slick-slider {
  width: 100% !important;
}

.ag-row .ag-cell {
  display: flex;
  align-items: center;
}

#installModalWrapper {
  height: 90vh !important
}

.ag-tooltip-hiding {
  opacity: 0;
}

/*
Prevent tooltip from getting clipped
*/
 .ag-root-wrapper {
  overflow: visible; 
}